<template>
  <div>
  <h5>Изменение пароля</h5>
  <el-form class="login-form" ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top" label-width="100px" >
    <el-form-item label="Новый пароль" prop="pass">
      <el-input
        v-model="ruleForm.pass" show-password
        type="password"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-form-item label="Ещё раз" prop="checkPass">
      <el-input
        v-model="ruleForm.checkPass" show-password
        type="password"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-button plain class="butt" type="warning" @click="re('ruleForm')"> Отмена</el-button>  
    <el-button class="butt" type="primary" @click="ok('ruleForm')" native-type='submit' > Изменить</el-button>  
  </el-form>
  <br>
  </div>
</template>

<script>

export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Введите новый пароль'))
      } else {
        var re=/^[а-яА-ЯA-Za-z0-9_\-@$#&*~:;!%.]{7,20}$/
        var OK = re.exec(value);
        if (!OK) {
          callback(new Error('Допустимы буквы, цифры, символы _-@$#&:;!%*~; не менее 7 символов'))
        } 
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Введите новый пароль ещё раз'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("Несовпадение!"))
      } else {
        callback()
      }
    }
    return {
      user: Object.assign({}, this.$store.getters['user_da']),
      ruleForm: {
        pass: '',
        checkPass: ''
      },
      rules: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
      }
    }
  },
  methods: {
    tmp () {console.log('userb=',this.$store.getters['user_da'],'user=',this.user)},
    re (formName) { this.$refs[formName].resetFields() },

    ok (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let title = `Ваш новый пароль: ${this.ruleForm.pass} `
          this.$confirm(title, 'Подтвердите изменение пароля!', {confirmButtonText: 'Да', cancelButtonText: 'Отмена', type: 'warning'})
            .then( ()=> {
              let updInfo = { id: this.user.id, password: this.ruleForm.pass }
              this.$store.dispatch('user/update', updInfo).then((msg) => {
                if (msg != "") {
                  this.$alert(msg, "Ошибка при изменении", {type: 'error', confirmButtonText: 'Закрыть', showClose: false})
                }
                else { 
                  this.$alert("Успешно", "", {type: 'success'})
                  this.re('ruleForm')
                }
              }).catch(() => { })
            }).catch(() => { })
        }
        else {
          this.$alert("Ошибка при заполнении", "", {type: 'error'})
        }
      })
    }

  },
}
</script>

<style  scoped>
.login-form {
    position: relative;
    width: 300px;
    max-width: 100%;
    padding: 20px 35px 0;
    background-color: rgb(232, 212, 233);
    margin: 0 auto;
    overflow: hidden;
}
.butt{
  margin-left:50px;
  margin-top:20px;
  margin-bottom:30px;
}
</style>